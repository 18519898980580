import React from "react";
import { Layout } from "./src/layout";
import browserLang from "browser-lang";

const fallbackLang = "en";

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => {
  // redirect to user's preferred language
  const locale = props.pageContext.locale;
  // Skip build, Browsers only
  // only try redirecting if user has not already picked a language, or URL locale differs from the picked language
  if (typeof window !== "undefined") {
    const langPicked = window.localStorage.getItem("customLang");
    if (langPicked === null || langPicked !== locale) {
      const preferredLang = browserLang({
        languages: ["en", "da"],
        fallback: fallbackLang
      });

      const path = props.location.pathname + props.location.search;
      if (locale !== preferredLang) {
        let newUrl = path.replace(locale, preferredLang);
        if (locale === fallbackLang) {
          newUrl = "/" + preferredLang.concat(path);
        }

        newUrl = newUrl.replace('/en/', '/');
        window.location.replace(newUrl);
      }
    }

    window.location.replace("https://www.beefit.io");
  }

  return <h4>Page has moved...</h4>;
};

export default wrapPageElement;
