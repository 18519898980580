// This file defines and configures the allowed languages
// Only one item MUST have the "default: true" key
module.exports = {
  en: {
    default: true,
    path: `en`,
    locale: `en-US`
  },
  da: {
    path: `da`,
    locale: `da-DK`
  }
};
