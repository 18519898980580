import React from 'react';
import Helmet from 'react-helmet';
import '../fonts/style.css';
import Footer from './footer';
import Header from './header';
import './layout.scss';

export interface IContext {
  locale: string;
}
const LocaleContext = React.createContext<IContext | null>(null);

const Layout: React.FC<any> = ({
  children,
  pageContext: { locale },
  location
}) => {
  return (
    <LocaleContext.Provider value={{ locale }}>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Helmet>
      <div className='container layout'>
        <Header pathname={location.pathname} locale={locale} />

        <div className='offset-behind-header'>
          <div>{children}</div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </LocaleContext.Provider>
  );
};

export { Layout, LocaleContext };
