import CustomLayout from "./wrapPageElement";

export let wrapPageElement = CustomLayout;

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    return import(`intersection-observer`);
  }
};
