import { Link } from 'gatsby';
import React from 'react';
// import localeConfig from '../../../i18n/i18n';
import { IContext, LocaleContext } from '../../layout';
import getLocalizedPath from '../../utils/getLocalizedPath';
import { document } from 'browser-monads';

// Use the globally available context to choose the right path
const LocalizedLink: React.FC<{
  to: string;
  style?: React.CSSProperties;
  activeStyle?: React.CSSProperties;
  particallyActive?: boolean;
}> = ({ style, activeStyle, to, particallyActive, ...props }) => {
  const { locale } = React.useContext(LocaleContext) as IContext;
  const path = getLocalizedPath(locale, to);

  return (
    <Link
      onClick={() => {
        document.querySelector('body').scrollTop = 0;
      }}
      {...props}
      style={style}
      activeStyle={activeStyle}
      to={path}
      partiallyActive={particallyActive}
    />
  );
};

export default LocalizedLink;
