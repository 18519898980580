import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  useTranslations,
  useTranslations_rawData_edges_node_translations
} from '../../gqltypes/useTranslations';
import { IContext, LocaleContext } from '../layout';

function getTranslations(): useTranslations_rawData_edges_node_translations {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = React.useContext(LocaleContext) as IContext;
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData }: useTranslations = useStaticQuery(query);

  if (!rawData) {
    throw new Error('No translation data found in getTranslations.ts');
  }

  // Simplify the response from GraphQL
  let simplified: Array<{
    name: string;
    translations: useTranslations_rawData_edges_node_translations;
  }> = [];

  simplified = rawData.edges.map((item: any) => {
    return {
      name: item.node.name,
      translations: item.node.translations
    };
  });

  // Only return translations for the current locale
  const { translations } = simplified.filter(lang => lang.name === locale)[0];

  return translations;
}

export default getTranslations;

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          relativePath
          name
          translations: childTranslationsJson {
            forgotPassword {
              title
              password
              confirmPassword
              submitted
              validationPasswordRequired
              validationPasswordConfirmRequired
              validationPasswordMatch
              confirm
              noToken
              expiredToken
              validationTooShort
            }
            faq {
              title
              typeYourQuestion
              viewAll
              didntFindSomething
              pleaseContactOurSupport
              emailDesc
              phoneDesc
            }
            privacy {
              title
              subtitle
              summaryTitle
              summaryText1
              summaryText2
              collectTitle
              collectText1
              collectText2
              collectText3
              collectText4
              collectText5
              collectText6
              thirdPartyTitle
              thirdPartyText
              legalTitle
              legalText
              storingTitle
              storingText
              childrenTitle
              childrenText
              contactTitle
              contactText
            }
            contact {
              title
              subtitle
              yourEmail
              titleOptional
              yourMessage
              sendMessage
              messageSent
            }
            about {
              jonasQuote
              jonasTitle
              theTeam
              theTeamSubtitle
              joinUs
              joinUsSubtitle
              applyNow
            }
            clubOwner {
              increaseRetention {
                title
                subtitle
              }
              howItWorks {
                title
                subtitle
                step1
                step2
                step3
              }
              beefitCreatesValue {
                title
                affordabilityTitle
                subtitle
                affordabilitySubtitle
                higherAttendanceTitle
                higherAttendanceSubtitle
                physicalRevenueTitle
                physicalRevenueSubtitle
                betterUseOfSpaceTitle
                betterUseOfSpaceSubtitle
                bigDataTitle
                bigDataSubtitle
                personalTrainingTitle
                personalTrainingSubtitle
              }
              sensors {
                title
                subtitle
                selectorDescription
                scannerDescription
                senseModuleDescription
              }
              supportedEquipment {
                title
                subtitle
                stackMachines
                cardioMachines
                barbells
                handWeightAreas
              }
              soundsInteresting {
                title
                subtitle
              }
            }
            customer {
              getMotivated {
                title
                subtitle
              }
              howItWorks {
                title
                subtitle
                step1
                step2
                step3
              }
              makeFitnessMoreFun {
                title
                subtitle
                getMotivated {
                  title
                  subtitle
                }
                reachYourGoals {
                  title
                  subtitle
                }
                connectWithFriends {
                  title
                  subtitle
                }
                workoutOutdoor {
                  title
                  subtitle
                }
                planYourWorkouts {
                  title
                  subtitle
                }
                doChallenges {
                  title
                  subtitle
                }
              }
              beefitInNumbers {
                title
                gyms
                exercises
                jumboJets
                cheeseburgers
              }
              readyToGetStarted {
                title
                subtitle
              }
            }
            links {
              customer
              clubOwner
              about
              faq
              contact
              privacyPolicy
            }
          }
        }
      }
    }
  }
`;
