import localeConfig from '../../i18n/i18n';

const getLocalizedPath = (locale: string, to: string) => {
  const isIndex = to === `/`;

  // TO DO: Fix this bad use of any
  const locales: any = localeConfig;

  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  // However, if the homepage/index page is linked don't add the "to"
  // Because otherwise this would add a trailing slash
  const path = locales[locale].default
    ? to
    : `/${locales[locale].path}${isIndex ? `/` : `${to}`}`;

  return path;
};

export default getLocalizedPath;
