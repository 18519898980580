import React from 'react';
import iosWhite from '../../../assets/atom/icon/button/ios-white.svg';
import iosBlack from '../../../assets/atom/icon/button/ios-black.svg';
import androidWhite from '../../../assets/atom/icon/button/android-white.svg';
import androidBlack from '../../../assets/atom/icon/button/android-black.svg';

export enum DownloadType {
  iOS,
  Android
}

interface IDownloadButtonProps {
  type: DownloadType;
  white?: boolean;
  width?: string;
}

export const DownloadButton: React.FC<IDownloadButtonProps> = props => {
  const isAndroid = props.type === DownloadType.Android;

  const text = isAndroid ? 'Android Download' : 'iOS Download';

  const colorClass = props.white ? 'is-white' : 'is-black';
  const btnClass = `button is-rounded ${colorClass}`;
  const android = props.white ? androidBlack : androidWhite;
  const ios = props.white ? iosBlack : iosWhite;
  const iconSrc = isAndroid ? android : ios;

  const width = props.width || 'auto';

  const googlePlayUrl =
    'https://play.google.com/store/apps/details?id=com.benefittechnologies.beefit';
  const appStoreUrl =
    'https://itunes.apple.com/dk/app/beefit-tracker/id1149084814?mt=8';

  const downloadUrl =
    props.type === DownloadType.Android ? googlePlayUrl : appStoreUrl;

  return (
    <a
      className={btnClass}
      href={downloadUrl}
      style={{
        lineHeight: '1.5rem',
        letterSpacing: '0.4px',
        fontSize: '1rem',
        fontWeight: 700,
        height: '4rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        width
      }}
    >
      <img src={iconSrc} style={{ marginRight: '1rem' }} />
      {text}
    </a>
  );
};
